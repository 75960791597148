<template>
  <button
    :disabled="disabled"
    :class="
      twMerge(
        'flex justify-center gap-3 rounded text-center text-sm font-medium transition-all duration-300 disabled:cursor-not-allowed',
        variant === 'secondary' ? secondary : primary,
        props.class
      )
    "
  >
    <slot>
      {{ text }}
    </slot>
  </button>
</template>
<script setup lang="ts">
import { twMerge } from "tailwind-merge";

const props = defineProps<{
  variant?: "primary" | "secondary";
  text?: string;
  disabled?: boolean;
  class?: string;
}>();

const primary =
  "px-5 py-4 bg-eerie-black text-white hover:bg-[#3a4044] disabled:bg-[#e1e1e1] disabled:text-lighter";
const secondary =
  "border border-stroke px-[19px] py-[15px] bg-white text-title-reversed underline decoration-transparent hover:decoration-title-reversed hover:border-eerie-black disabled:border-stroke disabled:bg-stroke disabled:text-lighter disabled:no-underline";
</script>
